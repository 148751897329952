.footer {
  margin: 0 auto;
  padding: 110px 34px 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;

  @media screen and (width <= 1025px) {
    padding: 80px 34px 32px;
  }

  @media screen and (width <= 991px) {
    padding-block: 80px 30px;
    padding-inline: clamp(1.5rem, 28.6486vw + -9.6014rem, 8.125rem);
  }

  @media screen and (width <= 769px) {
    padding-inline: 34px;
  }

  @media screen and (width <= 601px) {
    padding-inline: 14px;
    padding-top: 55px;
  }

  @media screen and (width >= 1900px) {
    padding-inline: 54px;
  }
}

.footer__inner-container {
  display: flex;
  justify-content: space-between;
}

.footer__content {
  justify-items: flex-start;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: clamp(3.275rem, 18.9417vw + -8.457rem, 12rem);
  grid-row-gap: clamp(2.75rem, 1.6282vw + 1.7415rem, 3.5rem);
  grid-template-areas:
    'navlinks email address'
    'subscribe phone social';

  @media screen and (width <= 991px) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: auto;
    grid-column-gap: 45px;
    grid-row-gap: 40px;
    justify-content: space-between;
    align-items: center;
    grid-template-areas:
      'navlinks email'
      'social phone'
      'subscribe address';
  }

  @media screen and (width <= 601px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 18px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    grid-template-areas:
      'navlinks'
      'email'
      'phone'
      'address'
      'social'
      'subscribe';
  }
}

.footer__navlinks {
  grid-area: navlinks;
  display: flex;
  align-items: baseline;
  gap: 45px;

  @media screen and (width <= 601px) {
    width: 100%;
    padding-bottom: 50px;
    margin: 0 auto 46px;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid black;
  }
}

.footer__navlink {
  font-family: var(--font-roboto-condensed), 'Roboto Condensed', system-ui, Areal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 2px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  transition: opacity 0.1s ease-out;
}

.footer__subscribe-form {
  grid-area: subscribe;
  width: 26vw;
  min-width: 325px;
  max-width: 400px;
}

.footer__input-label {
  font-family: var(--font-roboto-condensed), 'Roboto Condensed', system-ui, Areal;
  text-transform: uppercase;
  padding-bottom: 16px;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;

  @media screen and (width <= 991px) {
    font-size: 16px;
    line-height: 14px;
    padding-bottom: 0;
  }

  @media screen and (width <= 548px) {
    text-align: center;
  }
}

.footer__input-field {
  margin-bottom: 6px;
  border-bottom: 2px solid black;
  display: flex;
  align-items: baseline;

  @media screen and (width <= 601px) {
    margin-bottom: 0;
    border-bottom: 1px solid black;
  }
}

.footer__input-field_subscribed {
  margin-bottom: 6px;
  border-bottom: 2px solid transparent;
}

.footer__email-input {
  margin: 0;
  padding: 9px 9px 9px 0;
  font-size: 16px;
  line-height: 14px;
  border: 0;
  outline: 0;
  background: transparent;
  width: 100%;
  height: 37px;

  @media screen and (width <= 991px) {
    font-size: 14px;
  }
}

.footer__submit-btn {
  background-color: transparent;
  padding: 8px;
  border-radius: 35%;
  text-transform: uppercase;
  border: 2px solid black;
  box-shadow:
    0 0 1px 0 white inset,
    0 0 1px 0 white;
  transition: background-color 150ms ease-out;
  cursor: pointer;
  margin-left: 9px;

  @media screen and (width <= 601px) {
    padding: 7px;
  }
}

.footer__submit-btn:hover {
  background-color: black;
}

.footer__form-subtitle {
  font-size: 14px;
  margin: 0;
  padding: 10px 0 20px;
}

.footer__list-item {
  @media screen and (width <= 601px) {
    text-align: center;
  }
}

.footer__list-item_1 {
  grid-area: email;
  align-self: flex-start;
}

.footer__list-item_2 {
  grid-area: phone;
  align-self: end;
}

.footer__list-item_3 {
  grid-area: address;
  align-self: flex-start;
}

.footer__subtitle {
  font-family: var(--font-roboto-condensed), 'Roboto Condensed', system-ui, Areal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0 0 5px;

  @media screen and (width <= 991px) {
    font-size: 16px;
    margin-bottom: 3px;
  }

  @media screen and (width <= 601px) {
    margin: 0;
  }
}

.footer__contact {
  text-decoration: none;
  color: #171717;
  font-size: 18px;
  line-height: 18px;
  transition: opacity 0.1s ease-out;
  cursor: pointer;
  white-space: pre-line; // this is so the Address data wraps on a newline

  @media screen and (width <= 991px) {
    font-size: 16px;
    line-height: 14px;
  }
}

.footer__contact:hover,
.footer__navlink:hover {
  color: #737373;
}

.footer__sm-links {
  align-self: end;
  grid-area: social;
  display: flex;
  gap: 40px;

  @media screen and (width <= 601px) {
    gap: 30px;
    margin: 20px 0 40px;
  }
}

.footer__sm-link {
  object-fit: cover;
  width: 50px;
  aspect-ratio: 1/1;
  cursor: pointer;
  text-decoration: none;
  color: black;
  opacity: 0.8;
  transition: opacity 0.1s ease-out;
  // line-height: 0;

  @media screen and (width <= 991px) {
    width: 40px;
  }

  @media screen and (width <= 601px) {
    width: 30px;
  }
}

.footer__sm-link:hover {
  opacity: 0.6;
}

.footer__sm-img {
  width: 50px;
  height: 50px;

  @media screen and (width <= 991px) {
    width: 40px;
    height: 40px;
  }
}

.footer__bottom-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 46px;

  @media screen and (width <= 991px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 66px;
    padding-bottom: 20px;
  }

  @media screen and (width <= 601px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 18px;
    margin-top: 76px;
    padding-bottom: 6px;
  }
}

.footer__logo {
  height: 30px;
  aspect-ratio: 3.6333333333;
  opacity: 0.8;

  @media screen and (width <= 991px) {
    height: 25px;
  }

  @media screen and (width <= 601px) {
    height: 20px;
  }
}

.footer__copyright {
  margin: 0;
  font-size: 13px;
  line-height: 13px;

  @media screen and (width <= 991px) {
    font-size: 12px;
    line-height: 12px;
  }
}
